import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>
                    Dosser d'Actualitat i Cultura General per a Guàrdia Urbana de Barcelona 2024
                </Title>
                <Text>
                    Et presentem el millor dossier per preparar l'actualitat i la cultura general
                    per l'accés a les 128 places de la convocatòria de la Guàrdia Urbana de
                    Barcelona 2024.
                    <br />
                    <br />
                    Un any més i ja van 4 que tenim un dossier que sens dubte és una de les millors
                    eines per preparar aquesta fase.
                    <br />
                    <br />
                    Aquest any a dins del dossier trobaràs una sorpresa que estem segurs que us
                    encantarà i us facilitarà molt l'estudi.
                    <br />
                    <br />
                    A més trobareu:
                    <br />
                    <br />
                    - Els sis mesos d'actualitat que demanen a les bases de la convocatòria de juny
                    a novembre, en forma de titular i un breu resum.
                    <br />
                    <br />
                    - Cultura general amb il·lustracions basades en preguntes oficials
                    <br />
                    <br />
                    - Coneixements de la ciutat de Barcelona
                    <br />
                    <br />
                    - Premis, esports, personalitats
                    <br />
                    <br />
                    - Un simulacre final on et posarem a prova.
                    <br />
                    <br />
                    I una sorpresa final!!
                    <br />
                    <br />
                    Aquest dossier s'enviarà a partir del 2 de febrer.
                    <br />
                    <br />
                    No et quedis sense!
                    <br />
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
